import React from 'react'
import { Box, Heading, GridItem, Grid } from '@chakra-ui/react'
import { NextLevelContours } from './NextLevelContours'

export const PageBlock = ({ identifier, heading, children, variant, ...extraProps }) => {
    const formattedId = identifier ? identifier.replace(/[^a-zA-Z0-9]/g, '-') : `page-block-${Math.random() * 100}`

    return (
        <Grid
            id={formattedId}
            borderWidth="1px"
            borderColor={variant === 'minimal' ? 'transparent' : 'oriGrayscale.100'}
            borderX="none"
            width="100%"
            minHeight={{ base: '100vh', '2xl': 0 }}
            bg={variant === 'darkWithContours' ? 'oriGrayscale.900' : 'transparent'}
            color={variant === 'darkWithContours' ? 'oriGrayscale.50' : 'inherit'}
            {...extraProps}
        >
            {variant === 'darkWithContours' && (
                <GridItem gridColumn="1/1" gridRow="1/1" opacity={0.5}>
                    <NextLevelContours shouldIncludeText={false} lineColor="oriGrayscale.800" />
                </GridItem>
            )}
            <GridItem
                className="pageBlockInner"
                gridColumn="1/1"
                gridRow="1/1"
                display="flex"
                maxWidth="container.xl"
                mx="auto"
                flexDirection="column"
                gap={12}
                py={variant === 'minimal' ? 4 : 24}
                px={variant === 'minimal' ? 4 : 12}
            >
                {heading && (
                    <Box as="header" textAlign="center">
                        <Heading as="h2" size="3xl" variant="BLOCK_HEADING">
                            {heading}
                        </Heading>
                    </Box>
                )}
                {children && children}
            </GridItem>
        </Grid>
    )
}
