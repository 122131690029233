import React from 'react'
import { Box, Heading, ListItem, List, useTheme } from '@chakra-ui/react'

export const EntitledList = ({ heading, items }) => {
    const {
        constants: { LIST_UL_BULLETED },
    } = useTheme()

    return (
        <Box>
            <Heading as="h4" size="lg">
                {heading}
            </Heading>
            <List as="ul" variant={LIST_UL_BULLETED}>
                {items.map((item, index) => (
                    <ListItem key={index}>{item}</ListItem>
                ))}
            </List>
        </Box>
    )
}
